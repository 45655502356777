.common-header {
	padding: 3rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.common-heading {
	margin: 0;
	padding: 0 1rem;
	font-size: 2.3rem;
	font-weight: 700;
	color: #000;
}

.common-heading > p {
	margin: 0;
	padding: 0;
	color: grey;
	display: inline;
}

.header-home-link {
	text-decoration: none;
	color: #333;
}