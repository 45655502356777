body {
	overflow: hidden;
}

.common-container {
	padding: 0 10%;
	overflow: hidden;
}

.projects-intro {
	font-size: 1.5rem;
	font-weight: 400;
	color: #333;
}

.projects-github-link {
	font-weight: 800;
	text-decoration: none;
	color: #555;
}

.projects-content {
	background-color: #fafafa;
	margin-top: 1rem;
	border-radius: 20px 20px 0 0;
	height: 100%;
	overflow: scroll;
	padding: 10px 10px 160px 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	scrollbar-width: none;
}

.projects-content::-webkit-scrollbar {
	display: none;
}