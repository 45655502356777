@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Karla", sans-serif;
	color: #333;
	height: 100vh;
}

#root {
	height: 100%;
	width: 100%;
}

.common-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

::selection {
	background: #333;
	color: #fff;
}