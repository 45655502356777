.common-container-home {
	padding: 0 15%;
	height: 100%;
}

.home-content {
	height: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
}

.home-content > h2 {
	font-size: 2rem;
	font-weight: 700;
	color: #333;
	margin: 0;
	padding: 0;
}

.home-content > div {
	font-size: 1.5rem;
	font-weight: 400;
	color: #333;
}

.home-content > div > a {
	color: #555;
	font-weight: 800;
	text-decoration: none;
}

.university-name-0 {
	display: inline;
}

.university-name-1 {
	display: inline;
}