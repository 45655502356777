.common-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 10%;
}

.common-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background: radial-gradient(
		106.18% 312.71% at 6.94% 91.92%,
		#f3f3f3 0%,
		rgba(217, 217, 217, 0) 100%
	);
	padding: 20px;
	display: grid;
	grid-template-columns: 0.7fr 0.3fr;
	place-items: center;
}

.contact-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 3rem;
	padding: 20px;
}

.form-group {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

:root {
	--bg-gradient-ending-location-1: 110;
	--bg-gradient-ending-location-2: 2160;
	--gradient-start: #000000;
	--gradient-end: #e1e1e1;
}

.form-group > label {
	font-size: 1.6rem;
	font-weight: 600;
	color: #525252;
}

.form-group > input {
	display: flex;
	font-family: inherit;
	align-items: center;
	height: 3.3rem;
	font-size: 1.1rem;
	padding: 0 30px;
	font-weight: 400;
	color: black;
	border: 2px solid #f3f3f3;
	border-radius: 100px;
	background: linear-gradient(
		90deg,	
		rgba(132, 132, 132, 0.1) 0%,
		rgba(165, 165, 165, 0.2) 100%
	);
}

.form-group > input:hover {
	border: 2px solid #d2d2d2;
	transition: all 0.2s ease-in-out;
}

.form-group > input:focus {
	outline: none;
	border: 2px solid #e8e8e8;
	transition: all 0.2s ease-in-out;
}

.name-email-supergroup {
	display: flex;
	gap: 10px;
}

#message {
	resize: none;
	font-family: inherit;
	font-size: 1.1rem;
	padding: 15px 20px;
	border-radius: 20px;
	border: 2px solid #f3f3f3;
	background: linear-gradient(
		90deg,	
		rgba(132, 132, 132, 0.1) 0%,
		rgba(165, 165, 165, 0.2) 100%
	);
	color: black;
	height: 20rem;
}

#message:focus {
	outline: none;
	border: 2px solid #e8e8e8;
	transition: all 0.2s ease-in-out;
}

#message:hover {
	border: 2px solid #e8e8e8;
	transition: all 0.2s ease-in-out;
}

.contact-form > button {
	padding: 10px 15px;
	font-size: 1.1rem;
	font-weight: 600;
	font-family: inherit;
	color: black;
	border: none;
	border-radius: 100px;
	background: linear-gradient(
		45,	
		rgba(36, 36, 36, 1) 0%,
		rgb(52, 52, 52, 1) 100%
	);
	cursor: pointer;
	border: 2px solid #f3f3f3;
	width: 50%;
	height: 3.3rem;
}

.contact-form > button:hover {
	border: 2px solid #e8e8e8;
	transition: all 0.2s ease-in-out;
}