.common-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 7px;
	gap: 5px;
	border-radius: 100px;
	background-color: #f6f6f6;
}

.nav-link {
	padding: 0.6rem 1.1rem;
	font-size: 1.2rem;
	font-weight: 700;
	color: #000;
	text-decoration: none;
}

.nav-link:nth-child(1):hover {
	background: radial-gradient(72.62% 121.54% at 22.46% 38.71%, #EDEDED 0%, rgba(242, 242, 242, 0.465991) 48.44%, #E2E2E2 100%);
	border-radius: 100px 20px 20px 100px;
	box-shadow: 0px 0px 5px #C4C4C4;
}

.nav-link:nth-child(2):hover {
	background: radial-gradient(72.62% 121.54% at 22.46% 38.71%, #EDEDED 0%, rgba(242, 242, 242, 0.465991) 48.44%, #E2E2E2 100%);
	border-radius: 7px;
	box-shadow: 0px 0px 5px #C4C4C4;
}

.nav-link:nth-child(3):hover {
	background: radial-gradient(72.62% 121.54% at 22.46% 38.71%, #EDEDED 0%, rgba(242, 242, 242, 0.465991) 48.44%, #E2E2E2 100%);
	border-radius: 20px 100px 100px 20px;
	box-shadow: 0px 0px 5px #C4C4C4;
}

.nav-link:nth-child(1).active {
	background: radial-gradient(116.67% 456.75% at 22.46% 38.71%, #2D2D2D 0%, rgba(106, 106, 106, 0.465991) 48.44%, #000000 100%);
	border-radius: 100px 20px 20px 100px;
	box-shadow: 0px 0px 5px #C4C4C4;	
	color: white;
}

.nav-link:nth-child(2).active {
	background: radial-gradient(116.67% 456.75% at 22.46% 38.71%, #2D2D2D 0%, rgba(106, 106, 106, 0.465991) 48.44%, #000000 100%);
	border-radius: 7px;
	box-shadow: 0px 0px 5px #C4C4C4;
	color: white;
}

.nav-link:nth-child(3).active {
	background: radial-gradient(116.67% 456.75% at 22.46% 38.71%, #2D2D2D 0%, rgba(106, 106, 106, 0.465991) 48.44%, #000000 100%);
	border-radius: 20px 100px 100px 20px;
	box-shadow: 0px 0px 5px #C4C4C4;
	color: white;
}